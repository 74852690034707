import React, { useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { NavLink, Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./Meeting.css";

function Meeting({ id, title, status, creator, creatorName, user, userName }) {
  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  const selectColor = (status) => {
    if (status === "open") {
      return (
        <span className="pending-meeting">
          {t("Reuniones.Reunion.Abierta")}
        </span>
      );
    } else if (status === "closed") {
      return (
        <span className="waiting-meeting">
          {t("Reuniones.Reunion.Cerrada")}
        </span>
      );
    }
  };

  return (
    <Row className="meeting align-items-center">
      <Col lg={8} className="meeting-header">
        <NavLink className="nav-link" to={`/meetings/details/${id}`}>
          <h5>{title}</h5>
        </NavLink>
        <p className="creator">
          <strong>{t("Compromisos.Compromiso.CreadoPor")}</strong> {creatorName}
        </p>
        <p className="user">
          <strong>{t("Compromisos.Compromiso.AsignadoA")}</strong> {userName}
        </p>
      </Col>
      <Col lg={3} className="center">
        {selectColor(status)}
      </Col>
      <Col lg={1} className="meeting-content">
        {creator === state.userId ? (
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faCog} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={`/meetings/edit/${id}`}>
                <FontAwesomeIcon icon={faEdit} />{" "}
                {t("Compromisos.Compromiso.Editar")}
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={`/meetings/delete/${id}`}>
                <FontAwesomeIcon icon={faTrash} />{" "}
                {t("Compromisos.Compromiso.Eliminar")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
}

export default Meeting;
