import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./NewActionItem.css";
import { FaMinusCircle } from "react-icons/fa";

function NewActionItem({
  id,
  completed,
  description,
  dueDate,
  handleChangeInputAction,
  handleChangeDueDateActionItem,
  handleRemoveActionItem,
  english,
  disabled,
  status,
  users,
  handleChangeResponsibleActionItem,
  responsible,
}) {
  return (
    <Col className="new-action-item-container">
      <Col className="new-action-item-form mb-3">
        <Row className="justify-content-center">
          <Col lg={1} className="center">
            <Form.Check
              type="checkbox"
              id={id}
              name="completed"
              checked={completed}
              onChange={(e) => handleChangeInputAction(e, id)}
              disabled={disabled || status === "closed"}
            />
          </Col>
          <Col lg={6}>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows={2}
                name="description"
                placeholder="Descripción de la actividad a realizar"
                onChange={(e) => handleChangeInputAction(e, id)}
                style={{
                  marginBottom: "10px",
                }}
                value={description}
                disabled={disabled || status === "closed"}
              />
            </Form.Group>

            {english ? (
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description_english"
                  placeholder="Description of the topic to discuss"
                  onChange={(e) => handleChangeInputAction(e, id)}
                />
              </Form.Group>
            ) : (
              <></>
            )}
          </Col>
          <Col lg={2}>
            <Form.Group>
              <Form.Select
                onChange={(e) => handleChangeResponsibleActionItem(e, id)}
                value={responsible}
                disabled={disabled || status === "closed"}
              >
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <DatePicker
              selected={dueDate}
              name="dueDate"
              onChange={(e) => handleChangeDueDateActionItem(e, id)}
              disabled={disabled || status === "closed"}
            />
          </Col>
          <Col lg={1}>
            {disabled || status === "closed" ? (
              <></>
            ) : (
              <FaMinusCircle
                onClick={() => handleRemoveActionItem(id)}
                className="delete-agenda-item-icon"
              />
            )}
          </Col>
        </Row>
      </Col>
    </Col>
  );
}

export default NewActionItem;
