import React from "react";
import { Col } from "react-bootstrap";
import Meeting from "./Meeting";
import "./MeetingsList.css";

function MeetingsList({ meetings }) {
  return (
    <Col className="commitments-list">
      {meetings.map((element) => (
        <Meeting
          key={element._id}
          id={element._id}
          title={element.title}
          status={element.status}
          creator={element.creator}
          creatorName={element.creatorName}
          user={element.user}
          userName={element.userName}
          created_at={element.created_at}
        />
      ))}
    </Col>
  );
}

export default MeetingsList;
