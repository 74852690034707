import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import MyMeetingsList from "../Components/MyMeetingsList";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./MyMeetings.css";

function MyMeetings() {
  const [isLoading, setIsLoading] = useState(true);
  const [meetings, setMeetings] = useState([]);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/meetings/organization/${state.userId}`,
        data: {
          organization: state.organization,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setMeetings(response.data.meetings);
      setIsLoading(false);
    }
    fetch();
  }, [state.userId, state.organization, state.token]);

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={11} className="my-meetings-header">
            <h2>{t("Reuniones.MiReunion.Titulo")}</h2>
          </Col>
          <Col lg={1} className="right my-meetings-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/meetings/new`}>
                  <FontAwesomeIcon icon={faPlus} />{" "}
                  {t("Reuniones.MiReunion.NuevaReunion")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="align-items-center">
          {meetings.length === 0 ? (
            <Col className="empty-meetings center">
              <h5>{t("Reuniones.MiReunion.SinReuniones")}</h5>
            </Col>
          ) : (
            <MyMeetingsList meetings={meetings} />
          )}
        </Row>
      </Container>
    );
  }
}

export default MyMeetings;
