import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import "./NewAgendaItem.css";
import { FaMinusCircle } from "react-icons/fa";

function NewAgendaItem({
  id,
  completed,
  description,
  handleChangeInputAgenda,
  handleRemoveAgendaItem,
  english,
  disabled,
  status,
}) {
  return (
    <Col className="new-agenda-item-container">
      <Col className="new-agenda-item-form mb-3">
        <Row className="justify-content-center align-items-center">
          <Col lg={1} className="center">
            <Form.Check
              type="checkbox"
              id={id}
              name="completed"
              checked={completed}
              onChange={(e) => handleChangeInputAgenda(e, id)}
              disabled={disabled || status === "closed"}
            />
          </Col>
          <Col lg={10}>
            <Form.Group>
              <Form.Control
                type="text"
                name="description"
                placeholder="Descripción del tema a tratar"
                onChange={(e) => handleChangeInputAgenda(e, id)}
                style={{
                  textDecoration: completed === true ? "line-through" : "none",
                }}
                value={description}
                disabled={disabled || status === "closed"}
              />
            </Form.Group>
            {english ? (
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description_english"
                  placeholder="Description of the topic to discuss"
                  onChange={(e) => handleChangeInputAgenda(e, id)}
                />
              </Form.Group>
            ) : (
              <></>
            )}
          </Col>
          <Col lg={1}>
            {disabled || status === "closed" ? (
              <></>
            ) : (
              <FaMinusCircle
                onClick={() => handleRemoveAgendaItem(id)}
                className="delete-agenda-item-icon"
              />
            )}
          </Col>
        </Row>
      </Col>
    </Col>
  );
}

export default NewAgendaItem;
