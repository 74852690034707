import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import "./PerspectivesTable.css";

function PerspectivesTable({
  relationshipData,
  handleDetailsStatus,
  language,
  role,
}) {
  const { t } = useTranslation();

  const getRelationshipTitle = (relationship) => {
    switch (relationship) {
      case "client":
        if (language === "es") {
          return "Clientes";
        } else if (language === "en") {
          return "Clients";
        }
        break;
      case "peer":
        if (language === "es") {
          return "Homólogos";
        } else if (language === "en") {
          return "Peers";
        }
        break;
      case "boss":
        if (language === "es") {
          return "Jefe";
        } else if (language === "en") {
          return "Boss";
        }
        break;
      case "self":
        if (language === "es") {
          return "Auto";
        } else if (language === "en") {
          return "Self";
        }
        break;
      case "subordinate":
        if (language === "es") {
          return "Subordinados";
        } else if (language === "en") {
          return "Subordinates";
        }
        break;
      default:
        return "Error";
    }
  };
  return (
    <Col className="perspectives-table mb-3">
      <Col>
        {role === "admin" || role === "root" ? (
          <Form.Check
            type="switch"
            id="custom-switch"
            onChange={handleDetailsStatus}
          />
        ) : (
          <></>
        )}
      </Col>
      <Col>
        <Table striped>
          <thead>
            <tr>
              <th>{t("Reportes.Perspectiva")}</th>
              <th>{t("Reportes.Promedio")}</th>
              <th>{t("Reportes.Calificacion")}</th>
            </tr>
          </thead>
          <tbody>
            {relationshipData.map((item) => (
              <tr key={item.relationship}>
                <td>{getRelationshipTitle(item.relationship)}</td>
                <td>{item.mean.toFixed(2)}</td>
                <td>{item.grade.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Col>
  );
}

export default PerspectivesTable;
