import React, { useEffect, useContext, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Svg,
  Rect,
  Font,
} from "@react-pdf/renderer";
import AuthContext from "../../Context/AuthContext";
import { PDFViewer } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import axios from "axios";

function PdfReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [meeting, setMeeting] = useState({});

  const { state } = useContext(AuthContext);

  let { mid } = useParams();

  const dayjs = require("dayjs");

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/meetings/${mid}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setMeeting(response.data.meeting);

      setIsLoading(false);
    }
    fetch();
  }, []);

  Font.register({
    family: "Lato",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHvxk6XweuBCY.ttf",
        fontWeight: "regular",
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      flex: 1,
      backgroundColor: "#efefef",
      fontFamily: "Lato",
      padding: 30,
    },
    viewer: {
      width: window.innerWidth * 0.8, //the pdf viewer will take up all of the width and height
      height: window.innerHeight * 0.8,
    },
    header: {
      marginBottom: 20,
      flexDirection: "row",
      justifyContent: "space-between",
      alignContent: "center",
      alignItems: "center",
    },
    title: {
      fontSize: 25,
      textAlign: "center",
    },
    date: {
      fontSize: 12,
      textAlign: "center",
    },
    sectionTitle: {
      fontSize: 16,
      marginBottom: 10,
      fontWeight: "bold",
      borderBottom: 1,
      borderBottomColor: "#777",
      paddingBottom: 10,
    },
    agendaContainer: {
      marginBottom: 20,
    },
    agendaItem: {
      fontSize: 12,
      marginBottom: 10,
    },
    actionsContainer: {
      marginBottom: 20,
    },
    actionItemDescription: {
      fontSize: 12,
      width: "65%",
    },
    actionItemResponsible: {
      fontSize: 12,
      width: "20%",
    },
    actionItemDueDate: {
      fontSize: 12,
      width: "15%",
    },
    notesContainer: {
      marginBottom: 20,
    },
    noteUserName: {
      fontSize: 12,
      width: "20%",
    },
    noteMessage: {
      fontSize: 12,
      width: "80%",
    },
  });

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page size="LETTER" style={styles.page}>
            <View style={styles.header}>
              <Text style={styles.title}>{meeting.title}</Text>
              <Text style={styles.date}>
                {dayjs(meeting.last_updated).format("D-MMM-YYYY")}
              </Text>
            </View>
            <View style={styles.agendaContainer}>
              <Text style={styles.sectionTitle}>Agenda</Text>
              {meeting.agendaItems.map((item, index) => (
                <Text key={index} style={styles.agendaItem}>
                  {item.description}
                </Text>
              ))}
            </View>
            <View style={styles.actionsContainer}>
              <Text style={styles.sectionTitle}>Acciones</Text>
              {meeting.actionItems.map((item, index) => (
                <View
                  key={index}
                  style={{ flexDirection: "row", marginBottom: 10 }}
                >
                  <Text style={styles.actionItemDescription}>
                    {item.description}
                  </Text>
                  <Text style={styles.actionItemResponsible}>
                    {item.responsibleName}
                  </Text>
                  <Text style={styles.actionItemDueDate}>
                    {dayjs(item.dueDate).format("D-MMM-YYYY")}
                  </Text>
                </View>
              ))}
            </View>
            <View style={styles.notesContainer}>
              <Text style={styles.sectionTitle}>Notas y comentarios</Text>
              {meeting.comments.map((item, index) => (
                <View
                  key={index}
                  style={{ flexDirection: "row", marginBottom: 10 }}
                >
                  <Text style={styles.noteUserName}>{item.userName}</Text>
                  <Text style={styles.noteMessage}>{item.message}</Text>
                </View>
              ))}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );
  }
}

export default PdfReport;
