import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReportsList from "../Components/ReportsList";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import "./Reports.css";

function Reports() {
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/organization/reports/${state.organization}`,
        data: {
          role: state.role,
          language: state.language,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setReports(response.data.reports);
      setIsLoading(false);
    }
    fetch();
  }, [state.organization, state.role, state.language, state.token]);

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={11} className="reports-header">
            <h2>{t("Reportes.Titulo")}</h2>
          </Col>
          <Col lg={1} className="right reports-content">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to={`/reports/new`}>
                  <FontAwesomeIcon icon={faPlus} /> {t("Reportes.NuevoReporte")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center">
          {reports.length === 0 ? (
            <Col className="empty-reports center">
              <h5>{t("Reportes.SinReportes")}</h5>
            </Col>
          ) : (
            <ReportsList
              reports={reports}
              language={state.language}
              role={state.role}
            />
          )}
        </Row>
      </Container>
    );
  }
}

export default Reports;
