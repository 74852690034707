import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import StackedBarChart from "./StackedBarChart";
import QuestionStackedBar from "./QuestionStackedBar";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faList } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import OpenQuestionSummary from "./OpenQuestionSummary";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Alert from "react-bootstrap/Alert";
import { useTranslation } from "react-i18next";
import "./SurveyReport.css";

function SurveyReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [datasets, setDataSets] = useState([]);
  const [segmentationQuestions, setSegmentationQuestions] = useState([]);
  const [filter, setFilter] = useState("");
  const [questionResults, setQuestionResults] = useState([]);
  const [openQuestions, setOpenQuestions] = useState([]);
  const [finalGrade, setFinalGrade] = useState(0);
  const [key, setKey] = useState("closed");
  const [downloadUrl, setDownloadUrl] = useState("");

  const { state } = useContext(AuthContext);

  let { rid } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/reports/survey/data/${rid}`,
        data: {
          filter: filter,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });
      setLabels(response.data.labels);
      setDataSets(response.data.datasets);
      setQuestionResults(response.data.masterArray);
      setSegmentationQuestions(response.data.segmentationQuestions);
      setFinalGrade(response.data.finalGrade);
      setOpenQuestions([]);
      setIsLoading(false);
    }
    fetch();
  }, [filter]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const getOpenQuestionsResults = async () => {
    setIsLoading(true);
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/reports/survey/data/open/${rid}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    setOpenQuestions(response.data.openQuestionsArray);
    setIsLoading(false);
  };

  const downloadSurveyReportCSV = async () => {
    setIsLoading(true);
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/reports/download/survey/csv/${rid}`,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    setDownloadUrl(response.data.url);

    setIsLoading(false);
  };

  if (isLoading === true) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => {
                setKey(k);
                if (k === "open") {
                  getOpenQuestionsResults();
                }
              }}
              className="mb-3"
            >
              <Tab eventKey="closed" title="Preguntas Cerradas">
                {downloadUrl === "" ? (
                  <></>
                ) : (
                  <Row>
                    <Col className="mb-3">
                      <Alert variant="success">
                        Tu reporte ha sido generado con éxito.{" "}
                        <Alert.Link href={downloadUrl}>
                          Descargar reporte.
                        </Alert.Link>
                        .
                      </Alert>
                    </Col>
                  </Row>
                )}

                <Row className="justify-content-center align-items-center">
                  <Col lg={11} className="mb-3">
                    <Form.Label>{t("Reportes.Segmentar")}</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={(e) => handleFilterChange(e)}
                      value={filter}
                    >
                      <option value="">{t("Reportes.SinFiltro")}</option>
                      {segmentationQuestions.map((item) => (
                        <option key={item.questionId} value={item.questionId}>
                          {item.questionDescription}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col lg={1} className="right">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <FontAwesomeIcon icon={faList} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => downloadSurveyReportCSV()}
                        >
                          <FontAwesomeIcon icon={faFileDownload} />{" "}
                          {t("Reportes.DescargarEncuesta")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>

                <Col className="finalGrade">
                  <Row className="justify-content-center align-items-center">
                    <Col lg={9} className="center">
                      <h2>{t("Reportes.CalificacionFinal")}</h2>
                    </Col>
                    <Col lg={3}>
                      <h3>{finalGrade.toFixed(2)}</h3>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <StackedBarChart labels={labels} datasets={datasets} />
                  {questionResults.map((item) => (
                    <>
                      <h2 className="sectionTitle">{item.sectionName}</h2>
                      {item.questions.map((question) => (
                        <QuestionStackedBar
                          key={question.questionDescription}
                          labels={question.labels}
                          datasets={question.datasets}
                          question={question.questionDescription}
                        />
                      ))}
                    </>
                  ))}
                </Col>
              </Tab>
              <Tab eventKey="open" title="Preguntas abiertas">
                <Col>
                  {openQuestions.map((item) => (
                    <OpenQuestionSummary
                      key={item.questionDescription}
                      description={item.questionDescription}
                      summary={item.summary}
                    />
                  ))}
                </Col>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default SurveyReport;
