import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function KnowledgeBase() {
  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col lg={10} className="reports-header">
          <Col>
            <iframe
              title="Knowledge Base"
              src="https://www.loom.com/embed/486352737919457e89cdad4ede94483e?sid=9d29a1c1-16cc-470a-acd5-76514b618884"
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              style={{ width: 600, height: 600 }}
            ></iframe>
          </Col>
          <Col>
            <iframe
              src="https://player.vimeo.com/video/1050963829?h=8e642a52a9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="New Recording - 1/27/2025, 5:56:29 PM"
              style={{ width: 600, height: 600 }}
            ></iframe>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}

export default KnowledgeBase;
