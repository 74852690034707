import React, { useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import {
  FaChalkboardTeacher,
  FaHome,
  FaPuzzlePiece,
  FaCubes,
  FaTags,
  FaUsers,
  FaEdit,
  FaCheckSquare,
  FaChartBar,
  FaBuilding,
  FaBriefcase,
  FaHandsHelping,
  FaFootballBall,
  FaUserFriends,
} from "react-icons/fa";
import Image from "react-bootstrap/Image";
import { RiOrganizationChart } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import "./MainMenu.css";
import Logo from "../../Public/sphere-logo.png";

function MainMenu() {
  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  if (state.role === "root") {
    return (
      <Nav className="flex-column main-menu" as="ul">
        <Nav.Item as="li" className="center">
          <Image src={Logo} />
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/"}>
            <FaHome />
            Dashboard
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/behaviors"}>
            <FaPuzzlePiece />
            {t("Menu.Competencias")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/trainings/training-status"}>
            <FaChalkboardTeacher />
            {t("Menu.Capacitaciones")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/profiles"}>
            <FaCubes />
            {t("Menu.Perfiles")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/categories"}>
            <FaTags />
            {t("Menu.Categorias")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/people"}>
            <FaUsers />
            {t("Menu.Personas")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/orgchart"}>
            <RiOrganizationChart />
            {t("Menu.Organigrama")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/questionnaires"}>
            <FaEdit />
            {t("Menu.Cuestionarios")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/evaluations"}>
            <FaCheckSquare />
            {t("Menu.Evaluaciones")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/meetings"}>
            <FaUserFriends />
            {t("Menu.Reuniones")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/commitments"}>
            <FaHandsHelping />
            {t("Menu.Compromisos")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/reports"}>
            <FaChartBar />
            {t("Menu.Reportes")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/hobbies"}>
            <FaFootballBall />
            {t("Menu.Hobbies")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/organizations"}>
            <FaBuilding />
            {t("Menu.Organizaciones")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/accounts"}>
            <FaBriefcase />
            {t("Menu.Cuentas")}
          </NavLink>
        </Nav.Item>
      </Nav>
    );
  } else if (state.role === "owner") {
    <Nav className="flex-column main-menu" as="ul">
      <Nav.Item as="li" className="center">
        <Image src={Logo} />
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/"}>
          <FaHome />
          Dashboard
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/behaviors"}>
          <FaPuzzlePiece />
          {t("Menu.Competencias")}
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/trainings/training-status"}>
          <FaChalkboardTeacher />
          {t("Menu.Capacitaciones")}
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/profiles"}>
          <FaCubes />
          {t("Menu.Perfiles")}
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/categories"}>
          <FaTags />
          {t("Menu.Categorias")}
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/people"}>
          <FaUsers />
          {t("Menu.Personas")}
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/orgchart"}>
          <RiOrganizationChart />
          {t("Menu.Organigrama")}
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/questionnaires"}>
          <FaEdit />
          {t("Menu.Cuestionarios")}
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/evaluations"}>
          <FaCheckSquare />
          {t("Menu.Evaluaciones")}
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/meetings"}>
          <FaUserFriends />
          {t("Menu.Reuniones")}
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/commitments"}>
          <FaHandsHelping />
          {t("Menu.Compromisos")}
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/reports"}>
          <FaChartBar />
          {t("Menu.Reportes")}
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/hobbies"}>
          <FaFootballBall />
          {t("Menu.Hobbies")}
        </NavLink>
      </Nav.Item>

      <Nav.Item as="li">
        <NavLink className="nav-link" to={"/organizations"}>
          <FaBuilding />
          {t("Menu.Organizaciones")}
        </NavLink>
      </Nav.Item>
    </Nav>;
  } else if (state.role === "admin") {
    return (
      <Nav className="flex-column main-menu" as="ul">
        <Nav.Item as="li" className="center">
          <Image src={Logo} />
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" reloadDocument to={"/"}>
            <FaHome />
            Dashboard
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/behaviors"}>
            <FaPuzzlePiece />
            {t("Menu.Competencias")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/trainings/training-status"}>
            <FaChalkboardTeacher />
            {t("Menu.Capacitaciones")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/profiles"}>
            <FaCubes />
            {t("Menu.Perfiles")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/categories"}>
            <FaTags />
            {t("Menu.Categorias")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/people"}>
            <FaUsers />
            {t("Menu.Personas")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/orgchart"}>
            <RiOrganizationChart />
            {t("Menu.Organigrama")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/questionnaires"}>
            <FaEdit />
            {t("Menu.Cuestionarios")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/evaluations"}>
            <FaCheckSquare />
            {t("Menu.Evaluaciones")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/meetings"}>
            <FaUserFriends />
            {t("Menu.Reuniones")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/commitments"}>
            <FaHandsHelping />
            {t("Menu.Compromisos")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/reports"}>
            <FaChartBar />
            {t("Menu.Reportes")}
          </NavLink>
        </Nav.Item>
      </Nav>
    );
  } else {
    return (
      <Nav className="flex-column main-menu" as="ul">
        <Nav.Item as="li" className="center">
          <Image src={Logo} />
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" reloadDocument to={"/"}>
            <FaHome />
            Dashboard
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/trainings/training-status"}>
            <FaChalkboardTeacher />
            {t("Menu.Capacitaciones")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/mymeetings"}>
            <FaUserFriends />
            {t("Menu.Reuniones")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/mycommitments"}>
            <FaHandsHelping />
            {t("Menu.Compromisos")}
          </NavLink>
        </Nav.Item>

        <Nav.Item as="li">
          <NavLink className="nav-link" to={"/reports"}>
            <FaChartBar />
            {t("Menu.Reportes")}
          </NavLink>
        </Nav.Item>
      </Nav>
    );
  }
}

export default MainMenu;
