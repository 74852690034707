import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Breadcrumbs from "../../Navigation/Components/Breadcrumbs";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faLanguage } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import "./NewMeeting.css";
import NewAgendaItem from "./NewAgendaItem";
import { v4 as uuidv4 } from "uuid";

function NewMeeting() {
  const [user, setUser] = useState("");
  const [title, setTitle] = useState("");
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState("open");
  const [agendaItems, setAgendaItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [english, setEnglish] = useState(false);

  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    async function fetch() {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/meetings/users/${state.userId}`,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      setUsers(response.data.users);
      setAgendaItems([{ _id: uuidv4(), description: "", completed: false }]);
    }
    fetch();
    setIsLoading(false);
  }, [state.userId, state.token]);

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const activateEnglish = () => {
    setEnglish(true);
  };

  const handleChangeInputAgenda = (e, id) => {
    const newAgendaItemsData = agendaItems.map((i) => {
      if (id === i._id) {
        if (e.target.name === "completed") {
          i[e.target.name] = e.target.checked;
        } else {
          i[e.target.name] = e.target.value;
        }
      }
      return i;
    });

    setAgendaItems(newAgendaItemsData);
  };

  const handleAddAgendaItem = () => {
    setAgendaItems([
      ...agendaItems,
      {
        _id: uuidv4(),
        description: "",
        description_english: "",
        completed: false,
      },
    ]);
  };

  const handleRemoveAgendaItem = (id) => {
    let newAgendaItems = agendaItems.filter((i) => i._id !== id);
    setAgendaItems(newAgendaItems);
  };

  const handleSubmit = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/meetings`,
        data: {
          user: user,
          title: title,
          status: status,
          creator: state.userId,
          organization: state.organization,
          agendaItems: agendaItems,
        },
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      navigate("/mymeetings");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  let breadcrumbs = [
    {
      title: "Reuniones",
      path: "/mymeetings",
    },
  ];

  if (isLoading) {
    return <Spinner animation="border" role="status" />;
  } else {
    return (
      <Container fluid>
        <Row>
          <Breadcrumbs data={breadcrumbs} />
        </Row>
        <Row className="justify-content-center align-items-center">
          <Col lg={9} className="new-meeting-header">
            <h2>{t("Reuniones.Reunion.NewForm.Titulo")}</h2>
          </Col>
          <Col lg={1} className="right mb-2">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FontAwesomeIcon icon={faList} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={activateEnglish}>
                  <FontAwesomeIcon icon={faLanguage} /> English
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="justify-content-center align-items-center">
              <Col lg={10} className="general-info">
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("Reuniones.Reunion.NewForm.ParaQuien")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleUserChange}
                  >
                    <option value="">
                      {t("Reuniones.Reunion.NewForm.SeleccionarPersona")}
                    </option>
                    {users.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.firstName} {item.lastName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("Reuniones.Reunion.NewForm.TituloReunion")}
                  </Form.Label>
                  <Form.Control type="text" onChange={handleTitleChange} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="justify-content-center align-items-center">
              <Col lg={10}>
                <h3>{t("Reuniones.Reunion.NewForm.Agenda")}</h3>
              </Col>
            </Row>

            <Row className="align-items-center justify-content-center">
              <Col lg={10} className="agenda-items">
                {agendaItems.map((element) => (
                  <NewAgendaItem
                    id={element._id}
                    key={element._id}
                    title={element.title}
                    handleChangeInputAgenda={handleChangeInputAgenda}
                    handleAddAgendaItem={handleAddAgendaItem}
                    handleRemoveAgendaItem={handleRemoveAgendaItem}
                    english={english}
                    completed={element.completed}
                  />
                ))}
                <Row className="justify-content-center align-items-center">
                  <Col lg={10}>
                    <span
                      className="add-element"
                      onClick={() => handleAddAgendaItem()}
                    >
                      + Agregar tema a la agenda
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="justify-content-center align-items-center">
              <Col lg={10}>
                <Button className="primary" onClick={handleSubmit}>
                  {t("Reuniones.Reunion.NewForm.CrearReunion")}
                </Button>
                <Link to="/mymeetings">
                  <Button className="danger">
                    {t("Reuniones.Reunion.NewForm.Cancelar")}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default NewMeeting;
