import React, { useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { NavLink, Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./MyCommitment.css";

function MyCommitment({
  id,
  title,
  dueDate,
  status,
  creator,
  creatorName,
  userName,
  responsibleName,
}) {
  const { state } = useContext(AuthContext);

  const { t } = useTranslation();

  const dayjs = require("dayjs");

  const selectColor = (status) => {
    if (status === "Pending") {
      return (
        <span className="pending-commitment">
          {t("Compromisos.Compromiso.Pendiente")}
        </span>
      );
    } else if (status === "Waiting for approval") {
      return (
        <span className="waiting-commitment">
          {t("Compromisos.Compromiso.EsperandoAprobacion")}
        </span>
      );
    } else if (status === "Overdue") {
      return (
        <span className="overdue-commitment">
          {t("Compromisos.Compromiso.FueraDeTiempo")}
        </span>
      );
    } else if (status === "Completed") {
      return (
        <span className="completed-commitment">
          {t("Compromisos.Compromiso.Completado")}
        </span>
      );
    }
  };

  return (
    <Row className="commitment align-items-center">
      <Col lg={6} className="commitment-header">
        <NavLink className="nav-link" to={`/commitments/details/${id}`}>
          <h5>{title}</h5>
        </NavLink>
        <p className="creator">
          {creatorName} {t("Compromisos.Compromiso.Con")} {userName}
        </p>
        <p className="user">
          <strong>{t("Compromisos.Compromiso.AsignadoA")}:</strong>{" "}
          {responsibleName}
        </p>
      </Col>
      <Col lg={3} className="center">
        {selectColor(status)}
      </Col>
      <Col lg={2}>
        <h5 className="dueDate">{dayjs(dueDate).format("D-MMM-YYYY")}</h5>
      </Col>
      <Col lg={1} className="commitment-content">
        {creator === state.userId ? (
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <FontAwesomeIcon icon={faCog} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={`/commitments/edit/${id}`}>
                <FontAwesomeIcon icon={faEdit} />{" "}
                {t("Compromisos.Compromiso.Editar")}
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={`/commitments/delete/${id}`}>
                <FontAwesomeIcon icon={faTrash} />{" "}
                {t("Compromisos.Compromiso.Eliminar")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
}

export default MyCommitment;
