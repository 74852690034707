import React, { useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import AuthContext from "../../Context/AuthContext";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faUnlock,
  faSignOutAlt,
  faLanguage,
  faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./NavBar.css";

function NavBar() {
  const { state, signout, changeLanguage } = useContext(AuthContext);

  const { t } = useTranslation();

  const getInitials = (firstName, lastName) => {
    let tempFirstName = firstName.split("");
    let tempLastName = lastName.split("");
    return tempFirstName[0] + tempLastName[0];
  };

  return (
    <Navbar className="top-bar">
      <Container fluid>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <NavDropdown
            title={getInitials(state.firstName, state.lastName)}
            id="basic-nav-dropdown"
            className="top-bar-profile profile-name"
            drop="start"
          >
            <NavDropdown.Item
              as={Link}
              className="drop-options"
              to={`/my-profile`}
            >
              <FontAwesomeIcon icon={faUserCircle} /> {t("NavBar.MiPerfil")}
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              className="drop-options"
              to={`/change-password`}
            >
              <FontAwesomeIcon icon={faUnlock} />{" "}
              {t("NavBar.CambiarContrasena")}
            </NavDropdown.Item>

            {i18n.language === "en" ? (
              <NavDropdown.Item
                className="drop-options"
                onClick={() => changeLanguage("es")}
              >
                <FontAwesomeIcon icon={faLanguage} /> Cambiar a español
              </NavDropdown.Item>
            ) : (
              <NavDropdown.Item
                className="drop-options"
                onClick={() => changeLanguage("en")}
              >
                <FontAwesomeIcon icon={faLanguage} /> Change to english
              </NavDropdown.Item>
            )}
            {/* <NavDropdown.Item
              as={Link}
              className="drop-options"
              to={`/support`}
            >
              <FontAwesomeIcon icon={faTicketAlt} /> Ayuda
            </NavDropdown.Item> */}
            <NavDropdown.Item
              as={Link}
              className="drop-options"
              to={`/`}
              onClick={() => signout()}
            >
              <FontAwesomeIcon icon={faSignOutAlt} /> {t("NavBar.Salir")}
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
