import React from "react";
import { Col } from "react-bootstrap";
import MyCommitment from "./MyCommitment";
import "./MyCommitmentsList.css";

function MyCommitmentsList({ commitments }) {
  return (
    <Col className="commitments-list">
      {commitments.map((element) => (
        <MyCommitment
          key={element._id}
          id={element._id}
          title={element.title}
          dueDate={element.dueDate}
          status={element.status}
          creator={element.creator}
          userName={element.userName}
          creatorName={element.creatorName}
          responsibleName={element.responsibleName}
        />
      ))}
    </Col>
  );
}

export default MyCommitmentsList;
